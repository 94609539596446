import React, { createContext, useState, useContext, useCallback } from "react";
import CustomDialog from "../components/Popup/CustomDialog";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const [resolver, setResolver] = useState(null);

  const showDialog = useCallback((title, content, actions) => {
    return new Promise((resolve) => {
      setDialog({ open: true, title, content, actions });
      setResolver(() => resolve);
    });
  }, []);

  const hideDialog = useCallback(
    (result) => {
      setDialog((prev) => ({ ...prev, open: false }));
      if (resolver) {
        resolver(result);
      }
      setResolver(null);
    },
    [resolver]
  );

  const value = {
    showDialog,
    hideDialog,
  };

  return (
    <DialogContext.Provider value={value}>
      {children}
      <CustomDialog
        open={dialog.open}
        onClose={hideDialog}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  return useContext(DialogContext);
};
