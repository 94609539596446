import React from "react";
import { useLocation, Link } from "react-router-dom";

const ErrorPage = () => {
  const location = useLocation();

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>404 - Not Found</h1>
      <p>잘못된 경로로 접근하셨습니다:</p>
      <code>{location.pathname}</code>
      <br />
      <Link to="/">홈으로 돌아가기</Link>
    </div>
  );
};

export default ErrorPage;
