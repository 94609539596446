import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

const QuickToolBarContext = createContext();

export const QuickToolBarProvider = ({ children }) => {
  const [quickSelect, setQuickSelect] = useState(() => {
    const savedQuickSelect = sessionStorage.getItem("quickSelect");
    return savedQuickSelect ? JSON.parse(savedQuickSelect) : "";
  });
  const [quickMenuMobile, setQuickMenuMobile] = useState(() => {
    const savedQuickMenuMobile = sessionStorage.getItem("quickMenuMobile");
    return savedQuickMenuMobile ? JSON.parse(savedQuickMenuMobile) : [];
  });
  const [quickMenuPC, setQuickMenuPC] = useState(() => {
    const savedQuickMenuPC = sessionStorage.getItem("quickMenuPC");
    return savedQuickMenuPC ? JSON.parse(savedQuickMenuPC) : [];
  });

  useEffect(() => {
    sessionStorage.setItem("quickSelect", JSON.stringify(quickSelect));
  }, [quickSelect]);

  useEffect(() => {
    sessionStorage.setItem("quickMenuMobile", JSON.stringify(quickMenuMobile));
  }, [quickMenuMobile]);

  useEffect(() => {
    sessionStorage.setItem("quickMenuPC", JSON.stringify(quickMenuPC));
  }, [quickMenuPC]);

  const updateQuickSelect = useCallback((newQuickSelect) => {
    setQuickSelect(newQuickSelect);
  }, []);

  const updateQuickMenuMobile = useCallback((newQuickMenuMobile) => {
    setQuickMenuMobile(newQuickMenuMobile);
  }, []);

  const updateQuickMenuPC = useCallback((newQuickMenuPC) => {
    setQuickMenuPC(newQuickMenuPC);
  }, []);

  return (
    <QuickToolBarContext.Provider
      value={{
        quickSelect,
        setQuickSelect: updateQuickSelect,
        quickMenuMobile,
        setQuickMenuMobile: updateQuickMenuMobile,
        quickMenuPC,
        setQuickMenuPC: updateQuickMenuPC,
      }}
    >
      {children}
    </QuickToolBarContext.Provider>
  );
};

export const useQuickToolBar = () => {
  const context = useContext(QuickToolBarContext);
  if (!context) {
    throw new Error(
      "useQuickToolBar must be used within a QuickToolBarProvider"
    );
  }
  return context;
};
