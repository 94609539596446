// useNavigation.js
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useNavigation = () => {
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (path) => {
      if (path) {
        navigate(path.startsWith("/") ? path : `/${path}`);
      } else {
        navigate("/Error");
      }
    },
    [navigate]
  );

  return handleNavigation;
};

export default useNavigation;
