import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

// UserContext 생성
const UserContext = createContext();

// UserProvider 컴포넌트 정의
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = sessionStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [menu, setMenu] = useState(() => {
    const savedMenu = sessionStorage.getItem("menu");
    return savedMenu ? JSON.parse(savedMenu) : [];
  });

  useEffect(() => {
    if (user !== null) {
      sessionStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (menu.length > 0) {
      sessionStorage.setItem("menu", JSON.stringify(menu));
    }
  }, [menu]);

  const updateUser = useCallback((newUser) => {
    setUser(newUser);
  }, []);

  const updateMenu = useCallback((newMenu) => {
    setMenu(newMenu);
  }, []);

  return (
    <UserContext.Provider
      value={{ user, setUser: updateUser, menu, setMenu: updateMenu }}
    >
      {children}
    </UserContext.Provider>
  );
};

// UserContext를 사용하는 커스텀 훅
export const useUser = () => useContext(UserContext);
