//APP.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { UserProvider } from "./routes/UserContext";
import { QuickToolBarProvider } from "./routes/QuickToolBarContext";
import { DialogProvider } from "./routes/DialogContext";
import "./App.scss";
import "./styles/style.scss";
import Title from "./components/Layout/Title";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SnackbarProvider } from "./routes/SnackbarContext";

const App = () => {
  return (
    <UserProvider>
      <QuickToolBarProvider>
        <DialogProvider>
          <SnackbarProvider>
            <Router>
              <Title />
              <AppRoutes />
            </Router>
          </SnackbarProvider>
        </DialogProvider>
      </QuickToolBarProvider>
    </UserProvider>
  );
};

export default App;
