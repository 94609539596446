import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import Navigation from "./Navigation";
import Header from "./Header";
import {
  HEADER_HEIGHT,
  DRAWER_WIDTH,
  QUICKTOOLBAR_HEIGHT,
} from "../Common/Constants";
// import useViewportHeight from "../Hook/useViewportHeight";
import useViewportWidth from "../Hook/useViewportWidth";

const Layout = ({
  children,
  appVersion,
  isMobile,
  isTablet,
  isDesktop,
  user,
  menu,
}) => {
  const [open, setOpen] = useState(() => {
    const savedState = sessionStorage.getItem("drawerOpen");
    return savedState !== null ? JSON.parse(savedState) : !isMobile;
  });
  // const vh = useViewportHeight(); // 모바일 페이지에서의 주소창 리사이즈를 위하여 처리함.
  const vw = useViewportWidth(); // 테블릿, 모바일 페이지에서의 가로세로 사이즈 조절을 위하여 사용
  useEffect(() => {
    sessionStorage.setItem("drawerOpen", JSON.stringify(open));
  }, [open]);

  useEffect(() => {
    if (isMobile || isTablet) {
      setOpen(false);
    }
  }, [isMobile, isTablet]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const calcMargin =
    (user.is_quickmenu_mobile === "Y" && isMobile) ||
    (user.is_quickmenu_pc === "Y" && isTablet)
      ? `calc( ${HEADER_HEIGHT}px + ${QUICKTOOLBAR_HEIGHT}px + 15px)`
      : `calc( ${HEADER_HEIGHT}px + 15px)`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: `${vw}px`,
      }}
    >
      <Header
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        isTablet={isTablet}
        isDesktop={isDesktop}
        user={user}
      />
      <Navigation
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        isTablet={isTablet}
        isDesktop={isDesktop}
        appVersion={appVersion}
        user={user}
        menu={menu}
      />

      <main
        style={{
          flexGrow: 1,
          marginTop: calcMargin,
          marginBottom: "15px",
          //데스크탑일때는 밀려서 main이 보이고, Tablet과 Mobile에서는 화면을 전체를 사용함
          marginLeft: isDesktop
            ? open
              ? `calc(${DRAWER_WIDTH}px + 3px)`
              : "56px"
            : "5px",
          marginRight: "5px",
          paddingLeft: "3px",
          paddingRight: "3px",
          // height: `calc(${
          //   vh * 100
          // }px - ${HEADER_HEIGHT}px - ${calcMarginBottom})`,
          // overflowY: isMobile ? "hidden" : "auto",
        }}
      >
        <Container>{children}</Container>
      </main>
    </div>
  );
};

export default Layout;
