//index.js
import React from "react";
import ReactDOM from "react-dom/client"; // ReactDOM from 'react-dom'에서 'react-dom/client'로 변경
import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container); // React 18의 createRoot 사용

root.render(<App />);

// serviceWorker.unregister(); // 서비스 워커 비활성화
serviceWorker.register(); // 서비스 워커 비활성화
