import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { renderIcon } from "../Common/MaterialUI";
import { useQuickToolBar } from "../../routes/QuickToolBarContext";
import useNavigation from "../Hook/NavigationHook";
import { QUICKTOOLBAR_HEIGHT } from "../Common/Constants";
const QuickToolBar = ({ quickMenu, isMobile, isTablet, isDesktop }) => {
  const { quickSelect, setQuickSelect } = useQuickToolBar();
  const navigateTo = useNavigation();

  const handleChange = (event, newValue) => {
    setQuickSelect(newValue);
    const selectedMenu = quickMenu[newValue];
    if (selectedMenu) {
      navigateTo(selectedMenu.router_add);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        height: `${QUICKTOOLBAR_HEIGHT}px`,
        alignItems: "cneter",
      }}
    >
      <BottomNavigation
        showLabels
        value={quickSelect}
        onChange={handleChange}
        sx={{
          height: `${QUICKTOOLBAR_HEIGHT}px`,
          alignItems: "center",
        }}
      >
        {quickMenu.map((menuItem, index) => (
          <BottomNavigationAction
            key={menuItem.menu_id}
            value={index}
            label={menuItem.menu_name}
            icon={renderIcon(menuItem.icon)}
            sx={{
              alignItems: "center",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontsize: "0.8rem",
              border: "0px",
            }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default QuickToolBar;
