import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import useNavigation from "../Hook/NavigationHook";

import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Box,
  ListItemButton,
  IconButton,
  Avatar,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import {
  COMPANY_NAME,
  HEADER_HEIGHT,
  DRAWER_WIDTH,
  MOBILE_DRAWER_WIDTH,
  HEADER_COLOR,
} from "../Common/Constants";

import { renderIcon } from "../Common/MaterialUI";
import { useUser } from "../../routes/UserContext";
import authService from "../../services/authService";
// const Logo = styled("img")({
//   width: 50,
//   margin: "16px auto",
//   display: "block",
//   cursor: "pointer",
// });
const Navigation = ({
  open,
  isMobile,
  isTablet,
  isDesktop,
  handleDrawerToggle,
  appVersion,
  calcMarginBottom,
  vh,
}) => {
  const location = useLocation();
  const { user, menu } = useUser();
  const [openMenu, setOpenMenu] = useState({});
  const [selectedMenu, setSelectedMenu] = useState(location.pathname);
  const navigateTo = useNavigation();
  useEffect(() => {
    if (!open) {
      setOpenMenu({});
    }
  }, [open]);

  useEffect(() => {
    const findSelectedMenu = () => {
      const currentPath = location.pathname;
      const selected = menu.find(
        (menuItem) => currentPath === menuItem.router_add
      );

      return selected ? selected.router_add : currentPath;
    };

    const findParentMenu = (selectedPath) => {
      // console.log(selectedPath);
      const selected = menu.find(
        (menuItem) => menuItem.router_add === selectedPath
      );
      // console.log(selected);
      if (selected) {
        const parentMenu = menu.find(
          (menuItem) => menuItem.menu_id === selected.depth_parent
        );
        return parentMenu ? { [parentMenu.menu_id]: true } : {};
      }
      return {};
    };

    const selectedMenuPath = findSelectedMenu();
    setSelectedMenu(selectedMenuPath);
    // console.log(selectedMenuPath);

    const newOpenMenu = findParentMenu(selectedMenuPath);
    setOpenMenu(newOpenMenu);
    // console.log(newOpenMenu);
  }, [location, menu]);

  const handleLogout = async () => {
    await authService.logout();
    sessionStorage.clear();
    navigateTo("/login");
  };

  const toggleSubMenu = useCallback(
    (menuId) => {
      if (!open) {
        handleDrawerToggle();
      }
      setOpenMenu((prevState) => ({
        ...prevState,
        [menuId]: !prevState[menuId],
      }));
    },
    [open, handleDrawerToggle]
  );
  const renderAccount = () => {
    if (!open) return null;
    return (
      <Box
        sx={{
          my: 1.5,
          mx: 2.5,
          py: 1,
          px: 2.5,
          display: "flex",
          borderRadius: 1.5,
          alignItems: "center",
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
        }}
      >
        <Avatar src={user.photoURL} alt="photoURL" />

        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2">{user.user_name}</Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {user.hp}
          </Typography>
        </Box>
      </Box>
    );
  };
  const renderLogout = (
    <Box>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>{renderIcon("Logout")}</ListItemIcon>
        {open && <ListItemText primary="Logout" />}
      </ListItemButton>
      <Divider />
      {open && (
        <Typography
          variant="caption"
          component="span"
          pt={1}
          pb={2}
          align="center"
          display="block"
        >
          v{appVersion}
        </Typography>
      )}
    </Box>
  );
  const renderHeader = (
    <Box
      display={"flex "}
      sx={{
        alignItems: "center",
        paddingLeft: "16px",
        height: `${HEADER_HEIGHT}px`,
        backgroundColor: HEADER_COLOR,
      }}
    >
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={handleDrawerToggle}
        sx={{ marginRight: 1, color: "white" }}
      >
        {renderIcon("Menu")}
      </IconButton>
      <Typography variant="h6" sx={{ flexGrow: 1, color: "white" }}>
        {COMPANY_NAME}
      </Typography>
    </Box>
  );
  const renderMenuItem = useCallback(
    (menuItem) => {
      const hasSubMenu = menu.some(
        (subMenu) => subMenu.depth_parent === menuItem.menu_id
      );

      return (
        <div key={menuItem.menu_id}>
          <ListItemButton
            onClick={() =>
              hasSubMenu
                ? toggleSubMenu(menuItem.menu_id)
                : navigateTo(menuItem.router_add)
            }
            selected={selectedMenu === menuItem.router_add}
            sx={{ borderRadius: "50px", mb: 1 }}
          >
            <ListItemIcon>{renderIcon(menuItem.icon)}</ListItemIcon>
            {open && <ListItemText primary={menuItem.menu_name} />}
            {open &&
              hasSubMenu &&
              (openMenu[menuItem.menu_id]
                ? renderIcon("ExpandLess")
                : renderIcon("ExpandMore"))}
          </ListItemButton>

          {open && hasSubMenu && openMenu[menuItem.menu_id] && (
            <List disablePadding>
              {menu
                .filter((item) => item.depth_parent === menuItem.menu_id)
                .map((subMenuItem) => (
                  <ListItemButton
                    onClick={() => navigateTo(subMenuItem.router_add)}
                    key={subMenuItem.menu_id}
                    selected={selectedMenu === subMenuItem.router_add}
                    sx={{ ml: 3, pl: 2, mr: 2, borderRadius: "50px" }}
                  >
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "0.875rem" }}
                      primary={subMenuItem.menu_name}
                    />
                  </ListItemButton>
                ))}
            </List>
          )}
        </div>
      );
    },
    [menu, openMenu, selectedMenu, open, toggleSubMenu, navigateTo]
  );
  const drawerWidth = isMobile
    ? MOBILE_DRAWER_WIDTH
    : isTablet
    ? open
      ? DRAWER_WIDTH
      : 56
    : open
    ? DRAWER_WIDTH
    : 56;
  return (
    <Drawer
      variant={isDesktop ? "permanent" : "temporary"}
      open={open}
      onClose={handleDrawerToggle}
      flexgrow={1}
      sx={{
        width: drawerWidth,
        flexShrink: 0,

        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          transition: "width 0.3s",
          paddingBottom: "80px",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
        },
      }}
    >
      <Box>
        {renderHeader}
        {renderAccount()}

        <Divider />
        <List>
          {menu
            .filter((menuItem) => menuItem.depth === 1)
            .map((menuItem) => renderMenuItem(menuItem))}
        </List>
      </Box>
      <Divider />
      {renderLogout}
    </Drawer>
  );
};

export default Navigation;
