import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import authService from "../../services/authService";
// import image from "../../assets";
import {
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  useScrollTrigger,
  Slide,
  Box,
} from "@mui/material"; // 한 곳에서 import
import { renderIcon } from "../Common/MaterialUI";
import {
  HEADER_HEIGHT,
  COMPANY_NAME,
  QUICKTOOLBAR_HEIGHT,
  HEADER_COLOR,
  LINE_COLOR,
} from "../Common/Constants";
import QuickToolBar from "./QuickToolBar";
import { useQuickToolBar } from "../../routes/QuickToolBarContext";

// const Logo = styled("img")({
//   width: 50,
//   margin: "16px auto",
//   display: "block",
//   cursor: "pointer",
// });

const Header = ({
  handleDrawerToggle,
  isMobile,
  isTablet,
  isDesktop,
  user,
}) => {
  const { quickMenuMobile, quickMenuPC } = useQuickToolBar();
  // const navigate = useNavigate(); // useNavigate 훅 사용
  const [quickMenu, setQuickMenu] = useState([]);
  useEffect(() => {
    setQuickMenu(
      isMobile && user.is_quickmenu_mobile === "Y"
        ? quickMenuMobile
        : !isMobile && user.is_quickmenu_pc === "Y"
        ? quickMenuPC
        : []
    );
  }, [isMobile, user, quickMenuMobile, quickMenuPC]);
  const trigger = useScrollTrigger({
    target: window ? window : undefined,
  });

  const HideOnScroll = (props) => {
    const { children } = props;

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };

  // const handleLogoClick = () => {
  //   navigate("/dashboard");
  // };

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="fixed"
          sx={{
            paddingLeft: "16px",
            backgroundColor: HEADER_COLOR,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            height: HEADER_HEIGHT,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ marginRight: 1 }}
            >
              {renderIcon("Menu")}
            </IconButton>
            {/* <Logo src={image.logo} alt="Logo" onClick={handleLogoClick} /> */}
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {COMPANY_NAME}
            </Typography>
            {user.is_quickmenu_pc === "Y" && isDesktop && (
              <Box
                sx={{
                  backgroundColor: "white",
                  marginTop: "15px",
                  height: `${QUICKTOOLBAR_HEIGHT}px`,
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  borderBottom: "2px solid",
                  borderBottomColor: LINE_COLOR,
                }}
              >
                <QuickToolBar
                  quickMenu={quickMenu}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  user={user}
                  isDesktop={isDesktop}
                />
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {(user.is_quickmenu_mobile === "Y" && isMobile && !trigger) ||
      (user.is_quickmenu_pc === "Y" && isTablet && !trigger) ? (
        <Box
          sx={{
            position: "fixed",
            top: `${HEADER_HEIGHT}px`,
            height: `${QUICKTOOLBAR_HEIGHT}px`,
            width: "100%",
            zIndex: (theme) => theme.zIndex.drawer,
            transition: "top 0.3s",
            borderBottom: `2px solid`,
            borderBottomColor: LINE_COLOR,
          }}
        >
          <QuickToolBar
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
            user={user}
            quickMenu={quickMenu}
          />
        </Box>
      ) : null}
    </>
  );
};

export default Header;
