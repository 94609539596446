import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "./UserContext";

const PrivateRoute = ({ children }) => {
  const { user } = useUser();

  // 인증된 사용자 정보가 세션 스토리지에 있는지 확인
  if (!user) {
    sessionStorage.clear(); // 인증 정보가 없으면 세션을 정리하고 로그인 페이지로 리디렉션
    return <Navigate to="/login" />;
  }

  return children; // 인증된 사용자만 접근 가능
};

export default PrivateRoute;
