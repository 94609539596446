//Login.js
import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import authService from "../../../services/authService";
import image from "../../../assets";
import "./Login.scss";
import { COMPANY_NAME } from "../../../components/Common/Constants";
import { useUser } from "../../../routes/UserContext";
import { useQuickToolBar } from "../../../routes/QuickToolBarContext";
import useNavigation from "../../../components/Hook/NavigationHook";
const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setUser, setMenu } = useUser();
  const { setQuickMenuMobile, setQuickMenuPC } = useQuickToolBar();

  const navigate = useNavigation();
  // useEffect(() => {
  //   console.log("quickMenuPC:", quickMenuPC);
  //   console.log("quickMenuMobile:", quickMenuMobile);
  // }, [quickMenuMobile, quickMenuPC]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await authService.signin({ userId, password });
      //console.log(response);

      if (response.status === 200) {
        const result = await authService.getMenuByUserID({ userId });
        setUser(result.user);
        setMenu(result.menu);
        setQuickMenuMobile(result.quickMenuMobile);
        setQuickMenuPC(result.quickMenuPC);

        sessionStorage.setItem("user", JSON.stringify(result.user));
        sessionStorage.setItem("menu", JSON.stringify(result.menu));
        sessionStorage.setItem(
          "quickMenuPC",
          JSON.stringify(result.quickMenuPC)
        );
        sessionStorage.setItem(
          "quickMenuMobile",
          JSON.stringify(result.quickMenuMobile)
        );
        navigate("/DashBoard");
        // window.location.href = "/Dashboard";
      } else {
        setError("로그인실패!! ID/Password를 확인해주세요");
      }
    } catch (err) {
      setError("Invalid credentials");
    }
  };

  return (
    <Box
      className="signin-wrapper"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100vh"
    >
      <Box m={2} py={5} px={2} className="form-wrapper">
        <Box component="img" src={image.logo} alt="logo" className="logo" />
        <Typography
          variant="h6"
          fontWeight={"bold"}
          align="center"
          gutterBottom
        >
          {COMPANY_NAME}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="User ID"
            variant="outlined"
            fullWidth
            margin="normal"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            sx={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Login
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
