import React from "react";

// const loadIcon = (iconName) => {
//   return lazy(() => import(`@mui/icons-material/${iconName}`));
// };
// const renderIcon = (iconName) => {
//   const IconComponent = loadIcon(iconName);
//   return (
//     <Suspense fallback={<div>Loading...</div>}>
//       <IconComponent />
//     </Suspense>
//   );
// };

import * as Icons from "@mui/icons-material";
const renderIcon = (iconName, _color = false) => {
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    return null; // 아이콘이 존재하지 않을 경우 null 반환
  }

  return <IconComponent sx={_color ? { color: _color } : {}} />;
};

export { renderIcon };
