import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  // useCallback,
} from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Login from "../Pages/Auth/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PrivateRoute from "./PrivateRoute";
import Layout from "../components/Layout/Layout";
import { useUser } from "./UserContext";
// import { useQuickToolBar } from "./QuickToolBarContext";
// import authService from "../services/authService";
import ErrorPage from "../Pages/Error/Error"; // ErrorPage import 추가
import version from "../version";

const dynamicImport = (path) => {
  return lazy(() =>
    import(`../Pages/${path}`).catch((error) => {
      console.error(`Failed to load ${path}`, error);
      return { default: ErrorPage };
    })
  );
};

const AppRoutes = () => {
  const basicPath = "/dashboard";

  const { menu, user } = useUser();
  const location = useLocation();
  const [appVersion, setAppVersion] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    setAppVersion(version);
  }, []);
  const createComponent = (path) => {
    const Component = dynamicImport(path);

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Component
          isMobile={isMobile}
          isTablet={isTablet}
          isDesktop={isDesktop}
        />
      </Suspense>
    );
  };

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Navigate to={basicPath} />} />
      <Route path="/login" element={<Login />} />
      <Route
        path={"/Dashboard"}
        element={
          <PrivateRoute>
            <Layout
              appVersion={appVersion}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              user={user}
            >
              <Dashboard />
            </Layout>
          </PrivateRoute>
        }
      />
      {menu.map((menuItem) => (
        <Route
          key={menuItem.menu_id}
          path={`/${menuItem.router_add}/*`}
          element={
            <PrivateRoute>
              <Layout
                appVersion={appVersion}
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktop={isDesktop}
                user={user}
              >
                {createComponent(menuItem.router_page)}
              </Layout>
            </PrivateRoute>
          }
        />
      ))}
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Layout
              appVersion={appVersion}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              user={user}
            >
              <ErrorPage />
            </Layout>
          </PrivateRoute>
        }
      />{" "}
    </Routes>
  );
};

export default AppRoutes;
