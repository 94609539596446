import React, { useEffect, useState } from "react";

const DashBoard = () => {
  useEffect(() => {}, []);

  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
};

export default DashBoard;
