export const getAccessTokenFromCookies = () => {
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});

  const accessToken = cookies["access_token"];
  // console.log("Access Token:", accessToken);
  return accessToken || null;
};

export const getRefreshTokenFromCookies = () => {
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});

  const refreshToken = cookies["refresh_token"];
  // console.log("Refresh Token:", refreshToken);
  return refreshToken || null;
};
export const removeTokensFromCookies = () => {
  document.cookie = "access_token=; Max-Age=0; path=/;"; // 엑세스 토큰 삭제
  document.cookie = "refresh_token=; Max-Age=0; path=/;"; // 리프레시 토큰 삭제
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("menu");
};
