import logo from "./logo/logo.png";

// 다른 이미지 파일들을 여기에 추가

const images = {
  logo,

  // 다른 이미지 파일들을 여기에 추가
};

export default images;
