import axios from "axios";
import {
  getAccessTokenFromCookies,
  getRefreshTokenFromCookies,
  removeTokensFromCookies,
} from "../utils/token";

// axios.defaults.withCredentials = true;

const URL = process.env.REACT_APP_API_URL;

const API_URL = `${URL}/api/v1/auth/`;

// const API_URL = "http://enblab.iptime.org:28000/api/v1/auth/";

const signup = async (userData) => {
  const response = await axios.post(`${API_URL}signup`, userData);
  return response;
};

const signin = async (userData) => {
  const response = await axios.post(`${API_URL}signin`, userData);
  return response;
};

const refreshTokenfn = async () => {
  try {
    const response = await axios.post(
      `${API_URL}refreshToken`,
      {},
      { withCredentials: true }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

const getCurrentUser = async () => {
  const response = await axios.get(`${API_URL}me`);
  return response;
};

const changePassword = async (data) => {
  const response = await axios.post(`${API_URL}change-password`, data);
  return response;
};

const initPassword = async (data) => {
  const response = await axios.post(`${API_URL}init-password`, data);
  return response;
};

const logout = async () => {
  const response = await axios.post(`${API_URL}logout`);
  return response;
};

const getMenuByUserID = async (data) => {
  const response = await axios.post(`${API_URL}getMenuByUserID`, data);
  return response.data;
};

const checkAuth = async () => {
  let accessToken = getAccessTokenFromCookies();
  // console.log(accessToken);
  const refreshToken = getRefreshTokenFromCookies();

  if (!accessToken && refreshToken) {
    // console.log("여기진입안하지?");
    const response = await refreshTokenfn();
    if (response.status === 200) {
      accessToken = getAccessTokenFromCookies();
    } else {
      removeTokensFromCookies(); // 리프레시 토큰이 유효하지 않은 경우 토큰 삭제
    }
  }

  return !!accessToken;
};

const authService = {
  signup,
  signin,
  getCurrentUser,
  changePassword,
  initPassword,
  logout,
  refreshTokenfn,
  checkAuth,
  getMenuByUserID,
};

export default authService;
