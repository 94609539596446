import React from "react";
import { COMPANY_NAME } from "../Common/Constants";

import { Helmet } from "react-helmet";

const Title = () => {
  return (
    <Helmet>
      <title>{COMPANY_NAME}</title>
    </Helmet>
  );
};

export default Title;
